/*
    This is a utility function to handle the progress bar during loading of the App
*/
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/authStore'
import handleMessages from '@/utilities/handleMessages'

// Might use this function to check if the timeout is expired
/*

// function isPollTimedOut(time: number, timeout: number = 1000) {
    //     const now = Date.now();
    //     return now - time > timeout;
    // }

function isTimeoutExpired(): boolean {

        const timeoutMinutes: number = 11; // minutes before timeout
        // Fetch time from localStorage
        const storedTime: string | null = localStorage.getItem('refreshTimeout');
        // is there a stored time?
        if (!storedTime) {
            // set time in localStorage
            const currentTime: string = new Date().toISOString();
            localStorage.setItem('refreshTimeout', currentTime);
            console.log(`Set refresh timeout: ${timeoutMinutes} minutes from ${currentTime}`);
            return true;
        }
        const storedDate: Date = new Date(storedTime);
        const timeoutDuration: number = timeoutMinutes * 60 * 1000; // 1 uur in milliseconden
        const currentDate: Date = new Date();
        const isExpired: boolean = currentDate.getTime() - storedDate.getTime() > timeoutDuration;
        if (isExpired) {
            const newTime: string = new Date().toISOString();
            localStorage.setItem('refreshTimeout', newTime);
        }
        return isExpired;
    }
    */

const authStore = computed(() => (useAuthStore ? useAuthStore() : null))

const loadingTitle = ref<string>()
const nowLoading = ref('')
const open = ref(1)
const done = ref(0)
const progress = computed<number>(() => {
    if (open.value === 1) return 0
    const calculate = !open.value && !done.value ? 100 : Math.floor((done.value / open.value) * 100)
    if (calculate > 99) {
        open.value = 0
        done.value = 0
    }
    return calculate > 99 ? 100 : calculate
})
const loading = ref(false)

async function runRefresh(title?: string) {
    try {
        loadingTitle.value = title
        loading.value = true
        open.value = 1
        done.value = 0
        setOpen(computedReloadCbLength.value)

        for (const group in reloadFunctions.value) {
            const functions = reloadFunctions.value[group as RefreshGroup]
            for (const f of functions) {
                setLoading(f.name)
                await f.cb()
            }
        }
    } catch (error) {
        console.error('Error refreshing stores', error)
        handleMessages.setInfo('Oeps', 'Er ging iets mis met het inladen. Probeer het opnieuw.')
        authStore.value?.logout()
        authStore.value?.localStorageClear()
    } finally {
        // set the locale
        if (authStore.value && authStore.value.me.language_id && authStore.value.language) {
            const selectedLanguage = authStore.value.language.find((lang) => lang.id === authStore.value!.me.language_id)
            authStore.value.locale = selectedLanguage?.code ?? 'nl'
        }
        loading.value = false
        return
    }
}
function setLoading(section: string) {
    if (!section) return
    done.value += 1
    nowLoading.value = section
}
function setOpen(qty: number) {
    open.value += qty
}
function setDone(qty: number) {
    done.value += qty
}

export type RefreshGroup = 'auth' | 'comm' | 'cal' | 'org' | 'task'

const reloadFunctions = ref<Record<RefreshGroup, { name: string; cb: () => Promise<any> }[]>>({ auth: [], org: [], comm: [], cal: [], task: [] })

const computedReloadCbLength = computed(() => Object.keys(reloadFunctions.value).reduce((acc, key) => acc + reloadFunctions.value[key as RefreshGroup].length, 0))

const registerRefreshFunction = (group: RefreshGroup) => (name: string, cb: () => Promise<any>) => {
    const _group = [...reloadFunctions.value[group]]
    if (_group.find((r) => r.name === name)) return
    _group.push({ name, cb })
    reloadFunctions.value = { ...reloadFunctions.value, [group]: _group }
}

// cretae a function to handle errors
export default {
    loadingTitle,
    nowLoading,
    runRefresh,
    setLoading,
    setDone,
    setOpen,
    loading,
    progress,
    open,
    done,
    reloadFunctions,
    registerRefreshFunction,
    computedReloadCbLength,
}
