<script setup lang="ts">
    import { useAuthStore } from '@stores/authStore'
    import NotificationComponent from '@/components/notification/NotificationComponent.vue'
    const authStore = useAuthStore()
</script>

<template>
    <!-- the notification right -->
    <v-navigation-drawer width="305" permanent v-model="authStore.notificationPanel.show" theme="dark" color="blue-grey-darken-3" location="right" class="d-print-none">
        <notification-component />
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, auto) minmax(0, 1fr);
        gap: 1rem;
        height: 100dvh;
    }
</style>
