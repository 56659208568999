import { shallowRef, ref, watch, defineAsyncComponent } from 'vue'
import { OmitIdPartialCrudTypes, TypesMap } from '@components/crud/crudManager'
import GeneralCrud from '@components/crud/GeneralCrud.vue'

const dialog = ref(false)
const drawer = ref(false)
const type = ref<keyof TypesMap>('communication')
const asyncCrud = shallowRef<typeof GeneralCrud | null>(null)
const crudRef = ref<InstanceType<typeof GeneralCrud> | null>(null)

watch(drawer, (val) => {
    if (!val && crudRef.value) {
        crudRef.value.handleClose()
    }
})

export function useTheCrudDrawer() {
    if (!asyncCrud.value) {
        asyncCrud.value = defineAsyncComponent(() => import('@components/crud/GeneralCrud.vue'))
    }

    function switchToCreate(obj: OmitIdPartialCrudTypes) {
        if (!crudRef.value) return
        drawer.value = true
        crudRef.value.switchToCreate(obj)
    }
    function switchToQuickCreate(obj: OmitIdPartialCrudTypes) {
        if (!crudRef.value) return
        dialog.value = true
        crudRef.value.switchToQuickCreate(obj)
    }
    function switchToRead(id: number, asDialog: boolean = false) {
        if (!crudRef.value) return
        if (asDialog) dialog.value = true
        if (!asDialog) drawer.value = true
        crudRef.value.switchToRead(id)
    }
    function switchToUpdate(id: number) {
        if (!crudRef.value) return
        drawer.value = true
        crudRef.value.switchToUpdate(id)
    }
    function promptClose() {
        if (!crudRef.value) return
        crudRef.value.promptClose()
    }
    function toggleCQCreate() {
        if (!crudRef.value) return
        dialog.value = false
        drawer.value = true
        crudRef.value.action = 'create'
    }

    function setDeleteDisabled(val: boolean) {
        if (!crudRef.value) return
        crudRef.value.setDeleteDisabled(val)
    }

    function setSaveDisabled(val: boolean) {
        if (!crudRef.value) return
        crudRef.value.setSaveDisabled(val)
    }

    return {
        asyncCrud,
        dialog,
        drawer,
        type,
        switchToCreate,
        switchToQuickCreate,
        switchToUpdate,
        switchToRead,
        promptClose,
        toggleCQCreate,
        crudRef,
        setDeleteDisabled,
        setSaveDisabled,
    }
}
