// import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { TypesMap } from '@components/crud/crudManager'
import { type PermissionFlag, type UserFlag, type PermissionType, type PermissionAction } from '@/api/auth'
import thePermissions from '@/stores/permissions'
const { getPermissionTypes, getPermissionFlags, permissionFlags, permissionTypes } = thePermissions

// import { useAuthStore } from '@stores/authStore'
type EnhancedPermissionType = PermissionType | 'createRequest' | 'adminRequest'
// const authStore = useAuthStore()
// const { permissionFlags, permissionTypes } = storeToRefs(authStore)
function requestPermissionMapper(t: EnhancedPermissionType) {
    if (t == 'createRequest' || t == 'adminRequest') t = 'request'
    return t
}

export function useAuthorisation(type?: PermissionType) {
    const canAction = (action: PermissionAction) =>
        computed(() => (t = type) => {
            getPermissionTypes()
            if (!t) throw new Error('No type given')

            if (!permissionTypes.value) return false
            t = requestPermissionMapper(t)
            // Bit of redundancy to check for a mismatch in casing of TypesMap keys and PermissionTypes. This is to prevent a bug where a dev is trying to read key 'contenttype' but the key is actually 'contentType'
            if (!permissionTypes.value[t]) {
                const tempObj = Object.entries(permissionTypes.value).reduce((acc, [key, value]) => {
                    if (key == 'webuser') key = 'user'
                    return {
                        ...acc,
                        [`${key.toLocaleLowerCase()}`]: value,
                    }
                }, {}) as Record<keyof TypesMap, PermissionAction[]>

                if (!tempObj[t as keyof TypesMap]) return false
                return tempObj[t as keyof TypesMap].includes(action)
            }
            return permissionTypes.value[t].includes(action)
        })
    const canUpdate = canAction('update')
    const canDelete = canAction('delete')
    const canCreate = canAction('create')
    const canRead = canAction('read')

    // const canUpdate = computed(() => canAction('update'))
    // const canDelete = computed(() => canAction('delete'))
    // const canCreate = computed(() => canAction('create'))
    // const canRead = computed(() => canAction('read'))
    const isReadOnly = computed(() => async (t = type) => {
        await getPermissionTypes()
        if (!t) throw new Error('No type given')
        if (!permissionTypes.value) {
            console.error('no permissionTypes present')
            return false
        }
        t = requestPermissionMapper(t)
        return permissionTypes.value[t].includes('read') && permissionTypes.value[t].length === 1
    })

    const userFlag = computed(() => (flag: UserFlag) => {
        getPermissionTypes()
        if (!flag) throw new Error('No type given')
        if (!permissionTypes.value?.flags) return false
        return permissionTypes.value?.flags.includes(flag)
    })
    // function hasFlag(flag: PermissionFlag) {
    //     return computed(() => {
    //         getPermissionFlags()
    //         if (!permissionFlags.value) return false
    //         return permissionFlags.value[flag]
    //     })
    // }
    const hasFlag = computed(() => (flag: PermissionFlag) => {
        getPermissionFlags()
        if (!permissionFlags.value) return false
        return permissionFlags.value[flag]
    })

    const limits = computed(() => {
        const maxComms = permissionFlags?.value?.maxNumberOfCommunications
        const maxUsers = permissionFlags?.value?.maxUsers
        return { maxComms, maxUsers }
    })

    return {
        canCreate,
        canUpdate,
        canRead,
        canDelete,
        hasFlag,
        userFlag,
        limits,
        isReadOnly,
    }
}
