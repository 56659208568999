import { createRouter, createWebHistory } from 'vue-router'
import handleAlerts from '@/utilities/handleMessages'
// import LoginView from '../views/LoginView.vue'
import { useAuthStore } from '@/stores/authStore'
import { useCommunicationStore } from '@/stores/communicationStore'

// authentication
import { useAuthorisation } from '@/composables/authorisation'
import ThePermissions from '@/stores/permissions'
import { type PermissionFlag, type PermissionType } from '@/api/auth'
import { initialLoad, initialRoute } from '@/utilities/directRouteManager'

const authorisation = useAuthorisation()
const { getPermissionFlags } = ThePermissions

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'login',
            // component: LoginView,
            component: () => import('@views/LoginView.vue'),
        },
        {
            path: '/login/public/:uuid',
            name: 'public',
            // component: LoginView,
            component: () => import('@views/PublicLoginView.vue'),
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@views/HomeView.vue'),
        },
        {
            path: '/strategy',
            name: 'strategy',
            component: () => import('@views/StrategyView.vue'),
        },
        {
            path: '/strategy/:name',
            name: 'strategyName',
            component: () => import('@views/StrategyView.vue'),
        },
        {
            path: '/i18n',
            name: 'i18n',
            component: () => import('@views/I18nView.vue'),
            // meta: {
            //     isDev: true,
            //     fallback: 'home',
            // },
        },
        {
            path: '/communication/:id',
            name: 'communicationdetail',
            component: () => import('@views/CommunicationHandleView.vue'),
        },
        {
            path: '/communications',
            name: 'communications',
            component: () => import('@layouts/CommunicationLayout.vue'),
            children: [
                {
                    path: '',
                    name: 'calendar',
                    component: () => import('@views/communication/CommunicationCalendar.vue'),
                },
                {
                    path: 'list',
                    name: 'list',
                    component: () => import('@views/communication/CommunicationList.vue'),
                },
                {
                    path: 'kanban',
                    name: 'kanban',
                    component: () => import('@views/communication/CommunicationKanban.vue'),
                    meta: {
                        permissionFlag: 'canUseKanban',
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'kanban2',
                    name: 'kanban2',
                    component: () => import('@views/communication/CommunicationKanban2.vue'),
                    meta: {
                        permissionFlag: 'canUseKanban',
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'timeline',
                    name: 'timeline',
                    component: () => import('@views/communication/CommunicationTimeline.vue'),
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@views/communication/CommunicationDashboard.vue'),
                },
            ],
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: () => import('@layouts/TaskLayout.vue'),
            children: [
                {
                    path: '',
                    name: 'tasksCalendar',
                    component: () => import('@views/task/TaskCalendar.vue'),
                    meta: {
                        permissionType: 'task',
                        showIfOnlyRead: false,
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'list',
                    name: 'tasksList',
                    component: () => import('@views/task/TaskList.vue'),
                    meta: {
                        permissionType: 'task',
                        showIfOnlyRead: false,
                        fallback: 'calendar',
                    },
                },
            ],
        },
        {
            path: '/organisation',
            name: 'organisation',
            component: () => import('@views/OrganisationView.vue'),
            meta: {
                permissionType: 'organisation',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/calendars',
            name: 'calendars',
            meta: {
                permissionType: 'calendar',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
            component: () => import('@views/CalendarsView.vue'),
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@views/UsersView.vue'),
            meta: {
                permissionType: 'webuser',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/users/activate/:key',
            name: 'userActivate',
            component: () => import('@views/UsersActivateView.vue'),
        },
        {
            path: '/partner',
            name: 'partners',
            component: () => import('@views/PartnerView.vue'),
            meta: {
                permissionType: 'collaboration',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/workflows',
            name: 'workflows',
            component: () => import('@views/WorkflowsView.vue'),
            meta: {
                permissionType: 'workflow',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/holidays',
            name: 'holidays',
            component: () => import('@views/HolidaysView.vue'),
            meta: {
                permissionType: 'holiday',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/request',
            name: 'request',
            component: () => import('@views/requestView.vue'),
            meta: {
                permissionType: 'request',
                showIfOnlyRead: true,
                fallback: 'calendar',
            },
        },
        {
            path: '/account',
            name: 'account',
            component: () => import('@views/AccountView.vue'),
            meta: {
                permissionType: 'organisation',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@views/ProfileView.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('@views/SignupView.vue'),
        },
        {
            path: '/signup/activate/:key',
            name: 'signupActivate',
            component: () => import('@views/SignupActivateView.vue'),
        },
        {
            path: '/recurringcommunication',
            name: 'recurringcommunication',
            component: () => import('@views/CommunicationRecurringView.vue'),
        },
        {
            path: '/requestpassword',
            name: 'requestpassword',
            component: () => import('@views/RequestPasswordView.vue'),
        },
        {
            path: '/password-forgot/:key',
            name: 'resetpassword',
            component: () => import('@views/ResetPasswordView.vue'),
        },
        {
            path: '/test',
            name: 'test',
            component: () => import('@views/TestView.vue'),
        },
        {
            path: '/release',
            name: 'releasenotes',
            component: () => import('@views/ReleasenotesView.vue'),
        },
        {
            path: '/authorisationtest',
            name: 'authtest',
            component: () => import('@views/AuthorisationTestView.vue'),
        },
        {
            path: '/dev',
            name: 'dev',
            component: () => import('@views/DevView.vue'),
        },
        {
            path: '/sso/:uuid',
            name: 'ssoreturn',
            component: () => import('@views/SsoReturnView.vue'),
        },
        {
            path: '/generalerror',
            name: 'generalerror',
            component: () => import('@views/GeneralErrorView.vue'),
        },
        // {
        //     path: '/notification',
        //     name: 'notification',
        //     component: () => import('@views/NotificationView.vue'),
        // },
        // {
        //     path: '/:pathMatch(.*)*',
        //     name: 'NotFound',
        //     component: () => import('@views/HomeView.vue'),
        // },
    ],
})

async function permissionGuard(meta: { permissionType: PermissionType; showIfOnlyRead: boolean }) {
    if ((await authorisation.isReadOnly.value(meta.permissionType)) && !meta.showIfOnlyRead) {
        return false
    }
    return true
}

async function flagGuard(meta: { permissionFlag: PermissionFlag }) {
    await getPermissionFlags()
    if (meta.permissionFlag) {
        return authorisation.hasFlag.value(meta.permissionFlag)
    }
    return true
}

router.beforeEach(async (to, _, next) => {
    // Set communication detail
    if (to.name === 'communicationdetail') {
        const commStore = useCommunicationStore()
        commStore.communicationId = Number(to.params.id) || 0
    }
    // check if to.path contains 'communications'
    if (to.path.includes('communications')) {
        const commStore = useCommunicationStore()
        commStore.viewType = String(to.name) || ('calendar' as any)
    }

    const store = useAuthStore()
    const bypass = [
        'login',
        'register',
        'resetpassword',
        'signup',
        'signupActivate',
        'requestpassword',
        'password-forgot',
        'reset-password',
        'public',
        'userActivate',
        'ssoreturn',
        'generalerror',
        '',
        null,
    ]
    // const protectedRoutes = ['calendars', 'users', 'workflows', 'organisation']
    const name = to.name as string

    if (initialLoad.value && !initialRoute.value) {
        initialRoute.value = name
    }

    // if (store.isLoggedIn && name === 'login') {
    //     console.log('this ran')
    //     next({ name: defaultView.value })
    //     return
    // }

    if (bypass.includes(name)) {
        next()
        return
    }

    if (!store.isLoggedIn) {
        handleAlerts.setInfo('Log in om verder te gaan')
        next({ name: 'login' })
        return
    }

    // CUSTOM check Requests read
    if (to.path.includes('request')) {
        if (!authorisation.canRead.value('request')) {
            next({ name: 'calendar' })
            return
        }
    }

    // Routerguard: Check if permissionFlag is available, else fallback.
    if (Object.keys(to.meta).length && to.meta.permissionFlag && !(await flagGuard(to.meta as any))) {
        next({ name: (to.meta?.fallback as string) || 'calendar' })
        return
    }

    // Routerguard: Check if CRUD = create && is readonly, else fallback.
    if (Object.keys(to.meta).length && to.meta.permissionType && !(await permissionGuard(to.meta as any))) {
        next({ name: (to.meta?.fallback as string) || 'calendar' })
        return
    }

    next()
})

export default router
