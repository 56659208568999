import { ref } from 'vue'

const initialLoad = ref(true)
const initialRoute = ref('')

function setInitialRoute(route: string) {
    initialRoute.value = route
}

export { initialLoad, initialRoute, setInitialRoute }
