<script setup lang="ts">
    import { computed, onMounted, onUnmounted } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { storeToRefs } from 'pinia'
    const { t } = useI18n()

    //Router
    import { useRouter } from 'vue-router'
    const router = useRouter()

    import { useNotificationStore } from '@/stores/notificationStore'
    const store = useNotificationStore()
    const { notifications } = storeToRefs(store)

    import { useCommunicationStore } from '@/stores/communicationStore'
    const commStore = useCommunicationStore()
    const { communications } = storeToRefs(commStore)

    import { useTaskStore } from '@/stores/taskStore'
    const taskStore = useTaskStore()
    const { task } = storeToRefs(taskStore)

    import { _crudMap } from '@/components/crud/crudManager'
    import { useTheCrudDrawer } from '@/components/TheCrudDrawer'
    const crudDrawer = useTheCrudDrawer()

    import { TypesMap } from '@/components/crud/crudManager'
    import { nextTick, ref } from 'vue'
    const { type, switchToRead } = crudDrawer
    type.value = 'communication'

    const notificationInterval = ref()

    onMounted(() => {
        store.getNotifications()
        notificationInterval.value = setInterval(() => {
            store.getNotifications()
        }, 180 * 1000) // every 3 minutes
    })

    onUnmounted(() => {
        if (notificationInterval.value) {
            clearInterval(notificationInterval.value)
        }
    })

    function handleRead(item: any) {
        if (item.readNotification === false || item.readNotification === null) {
            item.readNotification = true
            store.updateNotification(item, item.id)
        }
    }

    async function handleReadAll() {
        store.allReadNotification()
    }

    async function openNotification(item: any) {
        let theType: keyof TypesMap = 'communication'
        let theId: number = 0
        if (item.notificationType === 'Task' && item.task_id !== 0 && item.communication_id === 0) {
            theType = 'task'
            theId = item.task_id
        } else if (item.notificationType === 'Task' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Communication' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'CommunicationDone' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Comment' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Request' && item.communication_id !== 0) {
            theType = 'adminRequest'
            theId = item.communication_id
        }
        try {
            if (theType == 'adminRequest') {
                router.push(`/request`)
                handleRead(item)
                return
            }
            if (theType && theId > 0) {
                type.value = theType
                await nextTick()
                switchToRead(theId)
                handleRead(item)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // All notifications that are clickable
    const clickableNofifications = computed(() => {
        return notifications.value?.filter((notification) => {
            return (
                notification.notificationType == 'Request' ||
                task.value.some((task) => {
                    return task.id === notification.task_id
                }) ||
                communications.value.some((comm) => {
                    return comm.id === notification.communication_id || comm.id === notification.communicationcomments_id
                })
            )
            return
        })
    })

    //FIXME : SY the Backend gave all text-translations that could not be mapped to the right tranlations. Therefore I have filtered the notificartion keys.
    const computedNotifications = computed(() => {
        return clickableNofifications.value
            ?.filter((n) => {
                return (
                    n.notificationText === 'notification.standard.text.comment' ||
                    n.notificationText === 'notification.standard.text.communication' ||
                    n.notificationText === 'notification.standard.text.communicationdone' ||
                    n.notificationText === 'notification.standard.text.request' ||
                    n.notificationText === 'notification.standard.text.task' ||
                    n.notificationText === 'notification.standard.text.request'
                )
            })
            .slice()
            .reverse()
        // return notifications.value?.slice().reverse()
    })
</script>

<template>
    <v-card variant="plain">
        <v-card-item>
            <v-card-title>
                <v-icon icon="fa:far fa-bell" size="extra-small"></v-icon>
                {{ t('generalKeys.notifications') }} ({{ clickableNofifications?.length }})</v-card-title
            >
        </v-card-item>
    </v-card>
    <v-divider></v-divider>
    <v-btn @click="handleReadAll" class="readAll-btn" variant="text" prepend-icon="fa-regular fa-flag">{{ t('notification.standard.readAll') }}</v-btn>
    <div @click="openNotification(item)" class="notification-div" v-for="item in computedNotifications">
        <p v-if="item?.readAt === null && item.notificationText">
            {{ t(item.notificationText) }}
        </p>
        <s v-if="item.readAt !== null && item.notificationText">{{ t(item.notificationText) }}</s>
    </div>
</template>

<style lang="scss" scoped>
    .notification-div {
        margin-bottom: 0.7rem;
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;

        p,
        s {
            color: rgb(224, 224, 224);
        }

        p:hover,
        s:hover {
            color: white;
        }
    }

    .readAll-btn {
        margin-top: 0.7rem;
        margin-bottom: 0.5rem;
        color: white;
        font-size: 0.9rem;
    }
</style>
