/** EXPLAINED
 * This file contains the task store used in the TaskComponent.vue as part of a communication
 */
import { defineStore } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { type WorkflowTask, type Task, type Workflow } from '@api/_apiTypes'

import refreshProgress from '@/utilities/refreshProgress' // loader utilities

const viewType = ref<'tasksList' | 'tasksListDev' | 'tasksTimeline' | 'tasksCalendar' | 'tasksKanban'>('tasksList')

import { apiGetTasks, apiGetTaskByCommunicationId, apiUpdateTask, apiCreateTask, apiDeleteTask, type CreateTaskBody } from '@/api/task'

import {
    apiGetWorkflows,
    apiUpdateWorkflow,
    apiCreateWorkflow,
    apiDeleteWorkflow,
    type CreateworkflowBody,
    apiCreateWorkflowTask,
    apiUpdateWorkflowTask,
    apiDeleteWorkflowTask,
    type CreateworkflowTaskBody,
} from '@/api/workflow'

export const useTaskStore = defineStore('task', () => {
    type FilteredTask = Task & {
        done: boolean
        webuser_shortcode: string | null
        webuser_name: string | null
        communication_id: number | null
        communication_name: string | null
        communication_color: string | null
        communication_hasTasks: boolean | null
        communication_project: string | null
        assignee_webuser_id: number | null
    }

    const task = ref<Task[]>([])
    const workflows = useLocalStorage('workflows', ref<Workflow[]>([]))
    const workflowTasks = useLocalStorage('workflowTasks', ref<WorkflowTask[]>([]))
    // !! filteredTask should not be used outside taskLayout context
    const filteredTask = ref<FilteredTask[]>([])

    function setFilteredTask(ft: []) {
        filteredTask.value = ft as FilteredTask[]
    }

    const communication_id = ref(0)
    const workflow_id = ref(0)
    const communication_guid = ref('')

    const uuid = ref('')

    onMounted(() => {
        const _refreshStore = refreshProgress.registerRefreshFunction('task')
        _refreshStore('tasks', refreshTasks)
        _refreshStore('workflows', getWorkflows)
    })

    async function refreshStore(all: boolean = true) {
        if (!all) {
            await refreshTasks()
            return true
        } else {
            try {
                await refreshTasks()
                await getWorkflows()
                return true
            } catch (error) {
                console.error('An error occurred:', error)
                return false
            }
        }
    }

    function resetStore() {
        task.value = []
        workflows.value = []
        workflowTasks.value = []
    }

    const populatedStore = computed(() => {
        return task.value.length > 0 && workflows.value.length > 0 && workflowTasks.value.length > 0
    })

    async function refreshTasks() {
        // const perPage = 9500
        const perPage = 1500
        const taskResponse = await apiGetTasks(1, perPage)
        // @ts-ignore
        if (taskResponse && taskResponse.items?.length) {
            task.value = await taskFixNull(taskResponse.items as Task[])
        }
        if (taskResponse && taskResponse.nextPage) {
            const status = true as boolean
            if (status == true) {
                lazyloadTasks(taskResponse.nextPage as number, perPage)
            } else {
                pendingTasks.value = false
            }
        }
    }
    const pendingTasks = ref(false) // indicator for lazy loading

    async function lazyloadTasks(page: number = 1, perPage: number = 1000) {
        pendingTasks.value = true
        const taskResponse = await apiGetTasks(page, perPage)
        // @ts-ignore
        if (taskResponse && taskResponse.items?.length) {
            task.value.push(...(await taskFixNull(taskResponse.items as Task[])))
        }
        if (taskResponse && taskResponse.nextPage) {
            lazyloadTasks(taskResponse.nextPage as number, perPage)
        } else {
            pendingTasks.value = false
        }
    }

    async function taskFixNull(taskArray: Task[]) {
        return taskArray.map((task) => {
            return {
                ...task,
                done: typeof task.done === null ? false : task.done,
                communication_id: task.communication_id && task.communication_id !== 0 ? task.communication_id : null,
                assignee_webuser_id: task.assignee_webuser_id && task.assignee_webuser_id !== 0 ? task.assignee_webuser_id : null,
            }
        })
    }

    // async function getTasks(id: number | 0) {
    //     if (id === 0) return {}
    //     const response = await apiGetTasks()
    //     if (response && response.items?.length) {
    //         task.value = response.items as Task[]
    //     }
    // }

    async function getTaskByCommunicationId(id: number | 0) {
        if (id === 0) return {}
        const response = await apiGetTaskByCommunicationId(id)
        if (response && response.items?.length) {
            response.items.forEach((tsk) => {
                const taskIndex = task.value.findIndex((t) => {
                    return t.id === tsk.id
                }) as number
                if (taskIndex !== -1) {
                    task.value[taskIndex] = tsk as Task
                } else {
                    task.value.push(tsk as Task)
                }
            })
        }
        return response
    }

    async function getTaskById(id: number | 0) {
        return task.value.find((t) => t.id === id)
    }

    // return the task based on a communication id
    const commTask = computed<Task[]>(() => {
        return task.value.filter((task) => {
            return task.communication_id === communication_id.value || task.guid === communication_guid.value
        })
    })

    const workflowTaskList = computed(() => {
        if (!workflows.value) return []

        const _currentWorkflow = workflows.value!.find((wf) => {
            return wf.id === workflow_id.value
        })
        if (!_currentWorkflow || !_currentWorkflow.actions) return []
        return _currentWorkflow.actions
    })

    async function createTask(body: CreateTaskBody) {
        const taskResponse = await apiCreateTask(body)
        if (taskResponse) {
            task.value.unshift(taskResponse as Task)
            return taskResponse
        }
    }

    async function updateTask(id: number, body: CreateTaskBody) {
        const taskResponse = await apiUpdateTask(id, body)
        if (taskResponse) {
            const taskId = task.value.findIndex((task) => {
                return task.id === id
            }) as number
            if (taskId !== -1) task.value[taskId] = taskResponse as Task
        }
    }

    async function deleteTask(id: number) {
        const taskResponse = await apiDeleteTask(id)
        if (taskResponse) {
            task.value = task.value.filter((task) => {
                return task.id !== id
            })
        }
    }

    // WORKFLOW
    async function getWorkflows() {
        const workflowsResponse = await apiGetWorkflows()
        if (workflowsResponse && workflowsResponse.items?.length) {
            workflows.value = workflowsResponse.items as Workflow[]
        }
    }

    async function createWorkflow(body: CreateworkflowBody) {
        const workflowResponse = await apiCreateWorkflow(body)
        if (workflowResponse) {
            workflows.value.unshift(workflowResponse as Workflow)
            return workflowResponse
        }
    }

    async function updateWorkflow(id: number, body: CreateworkflowBody) {
        const workflowResponse = await apiUpdateWorkflow(id, body)
        if (workflowResponse) {
            // replace workflow in store
            workflows.value = workflows.value.map((workflow) => {
                if (workflow.id === id) {
                    return workflowResponse
                }
                return workflow
            }) as Workflow[]
        }
    }

    async function deleteWorkflow(id: number) {
        const workflowResponse = await apiDeleteWorkflow(id)
        if (workflowResponse) {
            workflows.value = workflows.value.filter((workflow) => {
                return workflow.id !== id
            })
        }
    }

    // WORKFLOW TASK

    async function createWorkflowTask(body: CreateworkflowTaskBody & { update_existing?: boolean }) {
        const workflowTaskResponse = await apiCreateWorkflowTask(body)

        if (workflowTaskResponse) {
            const workflowIndex = workflows.value.findIndex((workflow) => {
                return workflow.id === body.workflow_id
            })
            workflows.value[workflowIndex].actions?.unshift(workflowTaskResponse as any)
            return workflowTaskResponse
        }
    }

    async function updateWorkflowTask(id: number, body: CreateworkflowTaskBody & { update_existing?: boolean }) {
        const workflowTaskResponse = await apiUpdateWorkflowTask(id, body)

        if (workflowTaskResponse) {
            const workflowIndex = workflows.value.findIndex((workflow) => {
                return workflow.id === body.workflow_id
            })
            const actions = ref(workflows.value[workflowIndex].actions)
            if (!actions.value) return
            const newActions = actions.value.map((action) => {
                if (action.id == id) return workflowTaskResponse
                return action
            })
            workflows.value[workflowIndex].actions = newActions
        }
    }

    async function deleteWorkflowTask(id: number, workflowId: number, delete_existing?: boolean) {
        const workflowTaskResponse = await apiDeleteWorkflowTask(id, delete_existing)
        if (workflowTaskResponse) {
            // build a new array of workflow actions
            const workflowIndex = workflows.value.findIndex((workflow) => {
                return workflow.id === workflowId
            })
            const actionsDelete = ref(workflows.value[workflowIndex].actions)
            if (actionsDelete.value && actionsDelete.value.length > 0) {
                const newActions = actionsDelete.value?.filter((action) => {
                    if (action.id !== id) return true
                    return false
                })
                workflows.value[workflowIndex].actions = newActions
            }
            // refetching all the tasks again
            if (delete_existing == true) refreshTasks()
            return
        }
    }

    return {
        viewType,
        task,
        pendingTasks,
        workflows,
        workflowTasks,
        setFilteredTask,
        filteredTask,
        commTask,
        communication_id,
        workflow_id,
        communication_guid,
        uuid,
        refreshStore,
        resetStore,
        populatedStore,
        refreshTasks,
        // getTasks,
        getTaskById,
        workflowTaskList,
        getTaskByCommunicationId,
        createTask,
        updateTask,
        deleteTask,
        getWorkflows,
        createWorkflow,
        updateWorkflow,
        deleteWorkflow,
        createWorkflowTask,
        updateWorkflowTask,
        deleteWorkflowTask,
    }
})
